.MuiDataGrid-panel button.MuiButtonBase-root {
  color: #f55b1a;
  font-weight: bold;
}

.MuiDataGrid-panel .MuiInputBase-root::before {
  border-bottom-color: #f55b1a;
}
.MuiDataGrid-panel .MuiInputBase-root::after {
  border-bottom-color: #f55b1a;
}

.MuiDataGrid-panel .MuiSwitch-switchBase.Mui-checked {
  color: #e64010;
}

.MuiDataGrid-panel .MuiFormLabel-root.Mui-focused {
  color: #e64010;
}
