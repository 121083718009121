section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@keyframes moveCar {
  0% {
    left: -110vw;
  }
  100% {
    left: 90vw;
  }
}

section .logo {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  align-content: center;
}

.logo .truck {
  position: relative;
  left: auto;
  animation: moveCar 10s linear infinite;

  margin-left: 250px;
  width: 100%;
  pointer-events: none;
}

.logo .road {
  position: absolute;
  margin-top: -8px;
  width: 100%;
  height: 12px;
  background: #323c6b;
}

.login {
  position: relative;
  padding: 60px;
  background: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(247, 120, 56, 0.9);
  border-bottom: 1px solid rgba(247, 120, 56, 0.9);
  border-right: 1px solid rgba(247, 120, 56, 0.9);
  border-radius: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);
}
/**it was h2 Sign  In*/
.login img {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  font-weight: 600;
  color: #8f2c24;
  margin-bottom: 10px;
  margin-left: 10px;
}

.login .inputBox {
  position: relative;
}

.login .inputBox input {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.25);
  font-size: 1.25em;
  color: #e64010;
  border-radius: 5px;
  background: #fff;
  border: none;
}

.login .inputBox .errorText {
  position: relative;
  margin: 0 10px;
  color: #b1271d;
}

.login .inputBox ::placeholder {
  color: #f77838;
}

.login .inputBox #btn {
  position: relative;
  border: none;
  outline: none;
  background: #e64010;
  color: #fff;
  cursor: pointer;
  font-size: 1.25em;
  font-weight: 500;
  transition: 0.5s;
}

.login .inputBox #btn:hover {
  background: #f77838;
}

.login .group {
  display: flex;
  justify-content: space-between;
}

.login .group a {
  font-size: 1.25em;
  color: #007f43;
  font-weight: 500;
  text-decoration: none;
}

.login .group a:nth-child(2) {
  text-decoration: underline;
}
